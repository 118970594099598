export const BlockPrivileges = (props) => {
    const label = props.label;
    const datas = props.datas;
    let picto = 'picto';
    switch (label) {
        case 'premieraccess':
            return (
                <div className={`${props.label}`}>
                    {datas.map((el,i) => {
                        picto = el.name.includes('Sans') ? 'picto disable' : 'picto';
                        return (
                            <div key={`i-${i}`} className={picto}>
                                <div className="fa-stack fa-lg">
                                    <div className="fa fa-square fa-stack-2x"></div>
                                    <div className={`fa ${props.icon} fa-stack-1x fa-inverse`}></div>
                                </div>
                                {el.name}
                            </div>
                        )
                    })}
                    {datas.map((el,i) => {
                        return (
                            <div key={`i-${i}`} className="note">{el.description}</div>
                        )
                    })}
                </div>
            )
        case 'pmr':
        case 'singlerider':
            return (
                <div className={props.label}>
                    <div className={picto}>
                        <div className="fa-stack fa-lg">
                            <div className="fa fa-square fa-stack-2x"></div>
                            <div className={`fa ${props.icon} fa-stack-1x fa-inverse`}></div>
                        </div>
                        {props.name}
                    </div>
                    <div className="note">{props.description}</div>
                </div>
            )
        case 'photopass':
        default:
            return (
                <div className={props.label}>
                    <div className={picto}>
                        <i className={`fa ${props.icon}`}></i>{props.name}
                    </div>
                    <div className="note">{props.description}</div>
                </div>
            )
    }
}