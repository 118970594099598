import { CleanAdminSession } from '../../../Admin/Functions/CleanAdminSession.js';

export const ButtonLogout = () => {
    return (
        <div className="button-logout">
            <a className="button" href="/admin/connexion" onClick={() => { CleanAdminSession(); }}>
                <i className="fa fa-power-off"></i>
            </a>
        </div>
    )
}