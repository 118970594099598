import { FilterBkgColor } from "../Filters/FilterBkgColor";

export const Jumbotron = (props) => {
    const datas = props.datas;
    return (
        <section className={`jumbotron ${FilterBkgColor()}`}>
            <div className="item-1">
                <div>{props.title}</div>
                <div>{props.sub}</div>
            </div>
            <div className="item-2">
                {datas.map((item, i) => {
                    return (
                        <div key={`i-${i}`}>{item.total}</div>
                    )
                })}
            </div>
        </section>
    )
}