export const GetTable = () => {
    let uri = window.location.href;
    let datas = {};
    if (uri.includes('/admin/')) {
        if (uri.includes('administrator')) datas = { label: 'administrator', table: 'administrator' };
        else if (uri.includes('attraction')) datas = { label: 'attraction', table: 'attraction' };
        else if (uri.includes('characters')) datas = { label: 'characters', table: 'characters' };
        else if (uri.includes('category')) datas = { label: 'categories', table: 'category' };
        else if (uri.includes('description')) datas = { label: 'description', table: 'description' };
        else if (uri.includes('origin')) datas = { label: 'origines', table: 'origin' };
        else if (uri.includes('outlinks')) datas = { label: 'outlinks', table: 'outlinks' };
        else if (uri.includes('park')) datas = { label: 'park', table: 'park' };
        else if (uri.includes('univers')) datas = { label: 'univers', table: 'univers' };
        else if (uri.includes('user')) datas = { label: 'user', table: 'user' };
        else datas = { label: 'attraction', table: 'attraction' };
    } else {
        if (uri.includes('personnage')) datas = { label: 'personnage', table: 'characters' };
        else if (uri.includes('origine')) datas = { label: 'origine', table: 'origin' };
        else datas = { label: 'attraction', table: 'attraction' };
    }
    return datas;
}