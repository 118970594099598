export const Date = (props) => {
    const name = props.name;
    const value = props.value ? props.value.slice(0, 10) : '';
    return (
        <div className="row">
            <label className="label" htmlFor={name}>{name}</label>
            <div>
                <input type="date" id={name} name={name} defaultValue={value} />
            </div>
        </div>
    )
}