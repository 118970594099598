import { ButtonDelete } from "../Form/Buttons/ButtonDelete.js";
import { ButtonUpdate } from "../Form/Buttons/ButtonUpdate.js";
import { TruncateString } from "../../Public/Functions/TruncateString.js";

export const Entities = (props) => {
    const entity = props.entity;
    const datas = props.datas;
    let name;
    switch (entity) {
        case 'administrator':
        case 'user':
            return (
                datas.map((el, i) => {
                    name = TruncateString(el.lastname, 30);
                    return (
                        <div key={`i-${i}`} className="rows">
                            <div className="block-rows">
                                <div className="id">{i + 1}</div>
                                <div className="name">{name}</div>
                                <div className="item">{el.firstname} ({el.id})</div>
                            </div>
                            <div className="block-buttons">
                                <ButtonUpdate id={el.id} entity={entity} />
                                <ButtonDelete id={el.id} entity={entity} />
                            </div>
                        </div>
                    )
                })
            )
        case 'attraction':
            return (
                datas.map((el, i) => {
                    name = TruncateString(el.name, 30);
                    return (
                        <div key={`i-${i}`} className="rows">
                            <div className="block-rows">
                                <div className="id">{i + 1}</div>
                                <div className="name">{name} <span>({el.id})</span></div>
                                <div className="item">{el.uname}</div>
                                <div className="item">{el.cname}</div>
                                <div className="item">{el.oname}</div>
                            </div>
                            <div className="block-buttons">
                                <ButtonUpdate id={el.id} entity={entity} />
                                <ButtonDelete id={el.id} entity={entity} />
                            </div>
                        </div>
                    )
                })
            )
        case 'characters':
            return (
                datas.map((el, i) => {
                    name = TruncateString(el.name, 30);
                    return (
                        <div key={`i-${i}`} className="rows">
                            <div className="block-rows">
                                <div className="id">{i + 1}</div>
                                <div className="name">{name} <span>({el.id})</span></div>
                                <div className="item">{el.pname}</div>
                                <div className="item">{el.oname}</div>
                            </div>
                            <div className="block-buttons">
                                <ButtonUpdate id={el.id} entity={entity} />
                                <ButtonDelete id={el.id} entity={entity} />
                            </div>
                        </div>
                    )
                })
            )
        case 'outlinks':
            return (
                datas.map((el, i) => {
                    name = TruncateString(el.name, 30);
                    return (
                        <div key={`i-${i}`} className="rows">
                            <div className="block-rows">
                                <div className="id">{i + 1}</div>
                                <div className="name">{name} <span>({el.id})</span></div>
                                <div className="item">{el.link}</div>
                            </div>
                            <div className="block-buttons">
                                <ButtonUpdate id={el.id} entity={entity} />
                                <ButtonDelete id={el.id} entity={entity} />
                            </div>
                        </div>
                    )
                }))
        case 'origin':
            return (
                datas.map((el, i) => {
                    name = TruncateString(el.name, 30);
                    return (
                        <div key={`i-${i}`} className="rows">
                            <div className="block-rows">
                                <div className="id">{i + 1}</div>
                                <div className="name">{name} <span>({el.id})</span></div>
                                <div className="item">{el.created.split('-')[0]}</div>
                                <div className="item">{el.otname}</div>
                            </div>
                            <div className="block-buttons">
                                <ButtonUpdate id={el.id} entity={entity} />
                                <ButtonDelete id={el.id} entity={entity} />
                            </div>
                        </div>
                    )
                }));
        case 'park':
        case 'univers':
        case 'category':
            return (
                datas.map((el, i) => {
                    name = TruncateString(el.name, 30);
                    return (
                        <div key={`i-${i}`} className="rows">
                            <div className="block-rows">
                                <div className="id">{i + 1}</div>
                                <div className="name">{name} <span>({el.id})</span></div>
                            </div>
                            <div className="block-buttons">
                                <ButtonUpdate id={el.id} entity={entity} />
                                <ButtonDelete id={el.id} entity={entity} />
                            </div>
                        </div>
                    )
                }));
        default:
            return (
                <></>
            )

    }
}
