export const GetBkgLogo = (route) => {
    let bkg = '';
    if (route.includes('attraction')) {
        if (route.includes('park-walt-disney-studios')) {
            bkg = 'bkg-logo-studios';
        }
        if (route.includes('park-park-disneyland')) {
            bkg = 'bkg-logo-park';
        }
    }
    return bkg;
}