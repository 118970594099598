export const ScrollTop = () => {
    let backtop = document.getElementById('backtop');
    let head = window.innerHeight;
    let lastPosition = 0;
    function hideAndShow(head, scrollPos) {
        if (scrollPos > (head-300)) {
            backtop.classList.remove('hide');
            backtop.classList.add('show');
        } else {
            backtop.classList.add('hide');
            backtop.classList.remove('show');
        };
    }
    document.addEventListener("scroll", (event) => {
        lastPosition = window.scrollY;
        hideAndShow(head, lastPosition);
    })
    backtop.addEventListener('click', () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    })
}