import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Experiments } from '../../Pages/Public/Experiments.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteExperiments = (props) => {
    const navParks = props.navParks;
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/experiences/stitch" element={<Experiments navParks={navParks} />} />
                <Route path="*" element={<NotFound navParks={navParks} />} />
            </Routes>
        </BrowserRouter>
    )
}