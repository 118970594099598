import { useState } from 'react';

export const Select = (props) => {
    const name = props.name;
    const chooser = props.chooser;
    const [newValue, setNewValue] = useState(props.value);
    return (
        <div className="row">
            <label className="label" htmlFor={name}>{name}</label>
            <select className="select" name={name} id={name} value={newValue} onChange={(e) => { setNewValue(e.target.value); return newValue }}>
                <option value="0">-- choose option --</option>
                {chooser.map((el, i) => {
                    return (
                        <option key={`i-${i}`} value={el.id}>{el.name}</option>
                    )
                })}
            </select>
        </div>
    )
}