import { useState } from 'react';
/* datas */
import { FilterBkgColor } from '../Filters/FilterBkgColor.js';
/* document */
import { Card } from './Card.js';

export const Carrousel = (props) => {
    const total = props.total;
    const incr = 300;
    const max = (incr * total) - incr;
    const [index, setIndex] = useState(1);
    const [margX, setMargX] = useState(0);
    const [showPrev, setShowPrev] = useState('hide');
    const [showNext, setShowNext] = useState('show');
    return (
        <section id="carrousel" className={`carrousel ${FilterBkgColor()}`}>
            <div className="block">
                <div id="prev" className={`prev ${showPrev}`} onClick={() => {
                    setIndex(index - 1);
                    setMargX(margX - incr);
                    if (margX < incr * 2) setShowPrev('hide');
                    if (index === 2) setShowNext('show')
                    }}><i className="fa fa-chevron-circle-left" aria-hidden="true"></i>
                </div>
                <div id="next" className={`next ${showNext}`} onClick={() => {
                    const wrapper = document.getElementById('wrapper');
                    setIndex(index + 1);
                    setMargX(margX + incr);
                    if (margX >= incr) setShowPrev('show');
                    if (wrapper.offsetWidth >= max) setShowNext('hide');
                }}><i className="fa fa-chevron-circle-right" aria-hidden="true"></i>
                </div>
                <div id="wrapper" className="wrapper" style={{ marginLeft: -margX + 'px' }}>
                    <Card label={props.label} datas={props.datas} />;
                </div>
            </div>
            <div className="hide" id="incr">{incr}</div>
            <div className="hide" id="max">{max}</div>
        </section>
    );
};
