import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';
import { BackTop} from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';

export const Category = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const id = parseInt(props.id);
    const [attractions, setAttractions] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const datasAttractions = await fetch(`${hosting}/attractions_by_category?id=${id}`);
            const attractions = await datasAttractions.json();
            setAttractions(attractions);
        };
        fetchData();
        ScrollTop();
    }, [hosting, id]);
    let items = {};
    const total = GetStringTotal('attraction', attractions.length);
    return (
        <div className="category">
            <Navigation navParks={navParks} />
            <Header />
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    {attractions.map((item, i) => {
                        items = item;
                        return (
                            <BlockLogo key={`i-${i}`} label="category" item={item} />
                        )
                    })}
                </div>
                <div className="description">
                    <BlockTitle label="category" item={items} total={total} />
                    <p>{items.description}</p>
                    <BlockOutlink item={items} />
                </div>
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}