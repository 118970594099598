import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';

export const NotFound = (props) => {
    const navParks = props.navParks;
    return (
        <div className="not-found">
            <Navigation navParks={navParks} />
            <Header />
            <section className="404">
                <div className="stitch"></div>
                <div className="not">404</div>
                <div className="found">Page Not Found</div>
            </section>
            <Footer />
        </div>
    )
}