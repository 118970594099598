export const GetStringTotal = (label, total) => {
    let string = '';
    if (total === 1) {
        string = `${total} ${label}`;
    } else if (total > 1) {
        if (label === 'univers' | label === 'Misterious') {
            string = `${label}`;
        } else {
            string = `${label}s`;
        }
    }
    return (
        <>
            <span>{total}</span> {string}
        </>
    );
}