export const Password = (props) => {
    const value = props.value;
    return (
        <div className="row">
            <label className="label" htmlFor="password">Password (min 8 caracters)</label>
            <input type="password" id="password" name="password" defaultValue={value} minLength="8" placeholder="pass1234" required />
            <input type="checkbox" onClick={() => {
                let x = document.getElementById("password");
                x.type === "password" ? x.type = "text" : x.type = "password";
            }} />show
        </div>
    )
}