export const FilterAttractions = (parent, attractions, id) => {
    const collection = [];
    const map = new Map();
    for (const el of attractions) {
        if (parent === 'park') {
            if (!map.has(el.aslug) && el.pid === id) {
                map.set(el.aslug, true);
                collection.push({
                    pslug: el.pslug,
                    uslug: el.uslug,
                    cslug: el.cslug,
                    ccode: el.ccode,
                    aid: el.aid, 
                    aname: el.aname,
                    aslug: el.aslug, 
                    aroute: el.aroute,
                });
            }
        }
        if (parent === 'univers') {
            if (!map.has(el.aslug) && el.uid === id) {
                map.set(el.aslug, true);
                collection.push({
                    pslug: el.pslug,
                    uslug: el.uslug,
                    cslug: el.cslug,
                    ccode: el.ccode,
                    aid: el.aid, 
                    aname: el.aname,
                    aslug: el.aslug, 
                    aroute: el.aroute,
                });
            }
        }
    }
    return collection;
}