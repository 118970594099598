import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { CountDatas } from '../../Components/Public/Functions/CountDatas.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';
import { BackTop} from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';

export const Origins = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const [body, setBody] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const bodyDatas = await fetch(`${hosting}/origins_body`);
            const body = await bodyDatas.json();
            setBody(body);
        };
        fetchData();
        ScrollTop();
    }, [hosting]);
    const total = GetStringTotal('origine', props.ori.length);
    const origins = props.ori ? props.ori : [];
    const tt_origin = CountDatas('origin', props.att);
    const tt_character = CountDatas('character', props.cha);
    return (
        <div className="origins">
            <Navigation navParks={navParks} />
            <Header />
            <BreadCrumb />
            <section className="infos">
                <div className="list">
                    {origins.map((item, i) => {
                        return (
                            <BlockLogo label="origins" item={item} tt_ori={tt_origin[item.oslug]} tt_car={tt_character[item.oslug]} key={`i-${i}`} />
                        )
                    })}
                </div>
                {body.map((item, i) => {
                    item['created'] = '1937-01-01';
                    item['author'] = 'Walt Disney Imagineering';
                    item['name'] = 'Films classiques d\'animations\ndes studios Disney';
                    return (
                        <div className="description" key={`i-${i}`}>
                            <BlockTitle label="origins" item={item} total={total} />
                            <p>{item.description}</p>
                            <BlockOutlink item={item} />
                        </div>
                    )
                })}
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}
