export const BreadCrumb = () => {
    const uri = window.location.href;
    const params = uri.split('/');
    /* attraction */
    if (uri.includes('attraction')) {
        return (
            <div className="breadcrumb">
                <a href={'/'} alt="home">home</a>
                <i className="fa fa-circle"></i>
                <a href={'/' + params[3]} alt={params[3]}>{params[3]}</a>
                <i className="fa fa-circle"></i>
                <a href={'/' + params[3] + '/univers/' + params[5]} alt={params[5]}>{params[5]}</a>
                <i className="fa fa-circle"></i>
                <span>{params[7]}</span>
            </div>
        )
    }
    /* categories | origins | movies */
    if (uri.includes('categories') | uri.includes('origines')) {
        return (
            <div className="breadcrumb">
                <a href={'/'} alt="home">home</a>
                <i className="fa fa-circle"></i>
                <span>{params[3]}</span>
            </div>
        )
    }
    /* category */
    if (uri.includes('categorie') && !uri.includes('categories')) {
        return (
            <div className="breadcrumb">
                <a href={'/'} alt="home">home</a>
                <i className="fa fa-circle"></i>
                <a href={'/categories'} alt="categories">Catégories</a>
                <i className="fa fa-circle"></i>
                <span>{params[4]}</span>
            </div>
        )
    }
    /* personnage */
    if (uri.includes('personnage') && !uri.includes('personnages')) {
        return (
            <div className="breadcrumb">
                <a href={'/'} alt="home">home</a>
                <i className="fa fa-circle"></i>
                <a href={'/personnages'} alt="personnages">Personnages</a>
                <i className="fa fa-circle"></i>
                <span>{params[5]}</span>
            </div>
        )
    }
    /* origin */
    if (uri.includes('origine') && !uri.includes('origines')) {
        return (
            <div className="breadcrumb">
                <a href={'/'} alt="home">home</a>
                <i className="fa fa-circle"></i>
                <a href={'/origines'} alt="origines">Origines</a>
                <i className="fa fa-circle"></i>
                <span>{params[4]}</span>
            </div>
        )
    }
    /* univers */
    if (uri.includes('univers') && !uri.includes('attraction')) {
        return (
            <div className="breadcrumb">
                <a href={'/'} alt="home">home</a>
                <i className="fa fa-circle"></i>
                <a href={'/' + params[3]} alt={params[3]}>{params[3]}</a>
                <i className="fa fa-circle"></i>
                <span>{params[5]}</span>
            </div>
        )
    }
    /* park */
    if (!uri.includes('categorie') && !uri.includes('univers') && !uri.includes('attraction')) {
        return (
            <div className="breadcrumb">
                <a href={'/'} alt="home">home</a>
                <i className="fa fa-circle"></i>
                <span>{params[3]}</span>
            </div>
        )
    }
}