/* document */
import { GetStringTotal } from '../Functions/GetStringTotal.js';

export const BlockExperiments = (props) => {
    const datas = props.exp.datas;
    const total = props.exp.total;
    return (
        <div className="load-experiments">
            <div className="title-main">{GetStringTotal('expérience', total)}</div>
            {datas.map((series, i) => {
                const title = series.title;
                const total_serie = series.data.length;
                const datas_serie = series.data;
                return (
                    <div key={`b-${i}`} className="block-list">
                        <div className="title-list">
                            <div>{`SERIE ${i}00: ${title}`}</div>
                            <div>{`${total_serie} expériences`}</div>
                        </div>
                        <div className="listing">
                            {datas_serie.map((item, i) => {
                                let str = item.split('/')[3].split('.')[0];
                                let code = str.split('-')[0];
                                let name = str.slice(4, str.length);
                                return (
                                    <div key={`i-${i}`} className="item">
                                        <div className="code">{code}</div>
                                        <div className="name">{name}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}