export const THead = (props) => {
    const switchComponentHead = () => {
        switch (props.uri) {
            case 'administrator':
            case 'user':
                return (
                    <div className="thead">
                        <div className="id">id</div>
                        <div className="name">lastname</div>
                        <div className="item">firstname</div>
                    </div>
                );
            case 'park':
            case 'univers':
            case 'category':
                return (
                    <div className="thead">
                        <div className="id">id</div>
                        <div className="name">name</div>
                    </div>
                );
            case 'origin':
                return (
                    <div className="thead">
                        <div className="id">id</div>
                        <div className="name">name</div>
                        <div className="name">date</div>
                        <div className="item">type</div>
                    </div>
                );
            case 'attraction':
                return (
                    <div className="thead">
                        <div className="block-rows">
                            <div className="id">id</div>
                            <div className="name">name</div>
                            <div className="item">univers</div>
                            <div className="item">category</div>
                            <div className="item">Origin</div>
                        </div>
                        <div className="block-buttons"></div>
                    </div>
                );
            case 'caracter':
                return (
                    <div className="thead">
                        <div className="id">id</div>
                        <div className="name">name</div>
                        <div className="item">park</div>
                        <div className="item">Origin</div>
                    </div>
                );
            case 'category':
            case 'description':
            case 'evacuation':
            case 'handicap':
            case 'height':
            case 'interest':
            case 'photopass':
            case 'pmr':
            case 'premieraccess':
            case 'public':
            case 'sensory':
            case 'singlerider':
                return (
                    <div className="thead">
                        <div className="id">id</div>
                        <div className="name">name</div>
                    </div>
                )
            default:
                return (
                    <div className="thead">
                        <div className="id">id</div>
                        <div className="name">name</div>
                    </div>
                )
        }
    }
    return (
        switchComponentHead()
    )
}