import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* datas */
import { HostBackend } from '../HostBackend.js';
/* document */
import { Home } from '../../Pages/Public/Home.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteHome = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const [navAttractions, setNavAttractions] = useState([]);
    const [navCharacters, setNavCharacters] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavAttractions = await fetch(`${hosting}/nav_attractions`);
            const navAttractions = await dataNavAttractions.json();
            setNavAttractions(navAttractions);
            const dataNavCharacters = await fetch(`${hosting}/nav_characters`);
            const navCharacters = await dataNavCharacters.json();
            setNavCharacters(navCharacters);
        };
        fetchData();
    }, [hosting]);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home navParks={navParks} att={navAttractions} cha={navCharacters} />} />
                <Route path="*" element={<NotFound navParks={navParks} />} />
            </Routes>
        </BrowserRouter>
    )
}