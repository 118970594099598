import { Exp000_HouseholdHelpers } from '../Experiments/Exp000_HouseholdHelpers.js';
import { Exp100_Disturbances } from '../Experiments/Exp100_Disturbances.js';
import { Exp200_Technological } from '../Experiments/Exp200_Technological.js';
import { Exp300_Psychological } from '../Experiments/Exp300_Psychological.js';
import { Exp400_Mysterious } from '../Experiments/Exp400_Mysterious.js';
import { Exp500_Environmental } from '../Experiments/Exp500_Environmental.js';
import { Exp600_Battlefield } from '../Experiments/Exp600_Battlefield.js';
import { Exp999_Others } from '../Experiments/Exp999_Others.js';

export const LoadExperiments = () => {
    const experiments = [
        { title: 'House Hold Helper', data: Exp000_HouseholdHelpers() },
        { title: 'Disturbance', data: Exp100_Disturbances() },
        { title: 'Technological', data: Exp200_Technological() },
        { title: 'Psychological', data: Exp300_Psychological() },
        { title: 'Misterious', data: Exp400_Mysterious() },
        { title: 'Environmental', data: Exp500_Environmental() },
        { title: 'Battlefield', data: Exp600_Battlefield() },
        { title: 'Other', data: Exp999_Others() },
    ];
    let tt = 0;
    experiments.map((exp) => { tt += exp.data.length; return tt; });
    let items = {
        created: '2002-06-22',
        author: 'Expériences Lilo & Stitch',
        name: 'Remerciements aux créateurs',
        deviantart: 'https://www.deviantart.com/',
        authors: ['bricerific43', 'sketch-lampoon', 'tombola1993', 'troytheartboy', 'tymore2000'],
    };
    const datas = {
        items: items,
        datas: experiments,
        total: tt,
    }
    return datas;
}