import { Text } from '../../Form/Inputs/Text.js';
import { Password } from '../../Form/Inputs/Password.js';
import { Email } from '../../Form/Inputs/Email.js';
import { Phone } from '../../Form/Inputs/Phone.js';
import { Date } from '../../Form/Inputs/Date.js';
import { Hidden } from '../../Form/Inputs/Hidden.js';

export const Administrator = (props) => {
    const firstname = (props.el) ? props.el.firstname : '';
    const lastname = (props.el) ? props.el.lastname : '';
    const email = (props.el) ? props.el.email : '';
    const password = (props.el) ? props.el.password : '';
    const token = (props.el) ? props.el.token : '';
    const date_start = (props.el) ? props.el.date_start : '';
    const date_end = (props.el) ? props.el.date_end : '';
    const date_current = (props.el) ? props.el.date_current : '';
    const address = (props.el) ? props.el.address : '';
    const zipcode = (props.el) ? props.el.zipcode : '';
    const city = (props.el) ? props.el.city : '';
    const country = (props.el) ? props.el.country : '';
    const mobile = (props.el) ? props.el.mobile : '';
    const id = (props.el) ? props.el.id : '';
    return (
        <>
            <Text name="firstname" value={firstname} />
            <Text name="lastname" value={lastname} />
            <Phone name="mobile" value={mobile} />
            <Email name="email" value={email} />
            <Password value={password} />
            <Text name="token" value={token} />
            <Date name="date_start" value={date_start} />
            <Date name="date_end" value={date_end} />
            <Date name="date_current" value={date_current} />
            <Text name="address" value={address} />
            <Text name="zipcode" value={zipcode} />
            <Text name="city" value={city} />
            <Text name="country" value={country} />
            <Hidden name="id" value={id} />
        </>
    )
}