import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* document */
import { Characters } from '../../Pages/Public/Characters.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteCharacters = (props) => {
    const navParks = props.navParks
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/personnages" element={<Characters navParks={navParks} />} />
                <Route path="*" element={<NotFound navParks={navParks} />} />
            </Routes>
        </BrowserRouter>
    )
}