import { GetIconCode } from "../Functions/GetIconCode";

export const BlockOutlink = (props) => {
    const item = props.item;
    return (
        <a className="link" href={item.link_href} target="_blank" rel="noreferrer" >
            <div className="code"><i className={GetIconCode('LK')}></i></div>
            <div className="name">{`Source: ${item.link_name}`}</div>
        </a>
    )
}