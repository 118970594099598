export const FilterOrigins = (parent, attractions, id) => {
    const collection = [];
    const map = new Map();
    for (const el of attractions) {
        if (parent === 'home') {
            if (!map.has(el.oslug)) {
                map.set(el.oslug, true);
                collection.push({
                    oid: el.oid,
                    oname: el.oname, 
                    oslug: el.oslug, 
                    oroute: el.oroute, 
                    ocreated: el.ocreated, 
                });
            }
        }
        if (parent === 'park') {
            if (!map.has(el.oslug) && el.pid === id) {
                map.set(el.oslug, true);
                collection.push({ 
                    oid: el.oid, 
                    oname: el.oname, 
                    oslug: el.oslug, 
                    oroute: el.oroute,
                    ocreated: el.ocreated, 
                });
            }
        }
        if (parent === 'univers') {
            if (!map.has(el.oslug) && el.uid === id) {
                map.set(el.oslug, true);
                collection.push({ 
                    oid: el.oid, 
                    oname: el.oname, 
                    oslug: el.oslug, 
                    oroute: el.oroute,
                    ocreated: el.ocreated, 
                });
            }
        }
    }
    return collection;
}
