import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Login } from '../../Pages/Admin/Login.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteLogin = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/admin/connexion'} element={<Login />} />
                <Route path={`/admin/connexion/:hash/:id`} element={<Login />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}