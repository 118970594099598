import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
    const token = (sessionStorage) ? sessionStorage.getItem('token') : '';
    if (sessionStorage.getItem('token') === token) {
        return <Outlet />;
    } else {
        return <Navigate to="/admin/connexion" />;
    }
};

export default PrivateRoute;
