import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../../Routes/HostBackend.js';
import { GetTable } from '../../../Components/Public/Functions/GetTable.js';
import { GetUpperCase } from '../../../Components/Public/Functions/GetUpperCase.js';

export const Finder = () => {
    const hosting = HostBackend();
    const entity = GetTable();
    let table = entity.table;
    let label = entity.label;
    const [show0, setShow0] = useState('hide');
    const [show1, setShow1] = useState('hide');
    const [find, setFind] = useState('');
    const [result, setResult] = useState([]);
    useEffect(() => {
        if (find.length > 0) {
            const fetchtheData = async () => {
                const dataResult = await fetch(`${hosting}/find?ent=${table}&find=${find}`);
                const result = await dataResult.json();
                setResult(result);
            };
            fetchtheData();
        }
    }, [hosting, table, find]);
    return (
        <div className="finder">
            <div className="label" onClick={(e) => { show0 === 'hide' ? setShow0('show') : setShow0('hide'); setShow1('hide'); }}>
                <div>{GetUpperCase(label)}</div>
                <i className="fa fa-search"></i>
            </div>
            <div className={`search ${show0}`}>
                <input type="text" max="50" value={find} name="find" onChange={(e) => { setFind(e.target.value); setShow1('show'); }} />
                <div className={`result ${show1}`}>
                    {result.map((el, i) => {
                        const name = (table === 'administrator' | table === 'user') ? el.lastname : el.name;
                        const href = `/admin/entity/update/${table}/${el.id}`;
                        return (
                            <a className="link" href={href} key={`i-${i}`}>{name}</a>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}