import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* admin routes */
import PrivateRoute from '../../Components/Admin/Functions/PrivateRoute.js';
import { Collection } from '../../Pages/Admin/Collection.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteCollection = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PrivateRoute />}>
                    <Route path={`/admin/entity/collection/:entity`} element={<Collection />} />
                    <Route path={`/admin/entity/collection/:entity/:sort`} element={<Collection />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}