export const Footer = () => {
    let name = 'h.douin.free.fr';
    let href = 'http://h.douin.free.fr';
    return (
        <footer className="footer">
            <ul>
                <li>Hubert DOUIN - Developper Frontend & Backend | <a href={href} target="_blank" rel="noreferrer" alt="DOUIN Hubert">{name}</a></li>
                <li>Apache - MySQL - PHP - Symfony - Magento - Docker - Nginx - NodeJS - ReactJS - ExpressJS</li>
            </ul>
        </footer>
    )
}

