import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { FilterCategory } from '../../Components/Public/Filters/FilterCategory.js';
import { LogoImgAttraction } from '../../Files/Logos/LogoImgAttraction.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { BlockCollection } from '../../Components/Public/Blocks/BlockCollection.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { GetStringTotal } from '../../Components/Public/Functions/GetStringTotal.js';
import { BackTop} from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';
import { FilterAttractions } from '../../Components/Public/Filters/FilterAttractions.js';

export const Univers = (props) => {
    const navParks = props.par;
    const id = parseInt(props.id);
    const hosting = HostBackend();
    const [body, setBody] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const bodyDatas = await fetch(`${hosting}/univers_body?id=${id}`);
            const body = await bodyDatas.json();
            setBody(body);
        };
        fetchData();
        ScrollTop();
    }, [hosting, id]);
    const attractions = FilterAttractions('univers', props.att, id);
    const categories = FilterCategory('univers', props.att, id);
    const str_total = GetStringTotal('attraction', attractions.length);
    const collection = [];
    for (let i = 0; i < attractions.length; i++) {
        collection.push({
            slug: attractions[i].aslug,
            name: attractions[i].aname,
            href: attractions[i].aroute,
            images: LogoImgAttraction('icon', attractions[i].aslug)
        });
    }
    return (
        <div className="univers">
            <Navigation navParks={navParks} />
            <Header />
            <Carrousel label="univers" datas={collection} total={attractions.length} />
            <BreadCrumb />
            <section className="infos">
                {body.map((item, i) => {
                    return (
                        <div className="bloc-infos" key={`i-${i}`}>
                            <BlockLogo label="univers" item={item} />
                            <BlockCollection label="categories" datas={categories} attractions={attractions} />
                            <BlockCollection label="univers" datas={attractions} />
                        </div>
                    )
                })}
                {body.map((item, i) => {
                    return (
                        <div className="description" key={`i-${i}`}>
                            <BlockTitle label="univers" item={item} total={str_total} />
                            <p>{item.description}</p>
                            <BlockOutlink item={item} />
                        </div>
                    )
                })}
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}