import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* admin routes */
import PrivateRoute from '../../Components/Admin/Functions/PrivateRoute.js';
import { Update } from '../../Pages/Admin/Update.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteUpdate = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PrivateRoute />}>
                    <Route path={`/admin/entity/update/:entity/:id`} element={<Update />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}