import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HostBackend } from '../HostBackend.js';
import { Origins } from '../../Pages/Public/Origins.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteOrigins = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const [navAttractions, setNavAttractions] = useState([]);
    const [navCaracters, setNavCaracters] = useState([]);
    const [navOrigins, setNavOrigins] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavAttractions = await fetch(`${hosting}/nav_attractions`);
            const navAttractions = await dataNavAttractions.json();
            setNavAttractions(navAttractions);
            const dataNavCaracters = await fetch(`${hosting}/nav_characters`);
            const navCaracters = await dataNavCaracters.json();
            setNavCaracters(navCaracters);
            const dataNavOrigins = await fetch(`${hosting}/nav_origins`);
            const navOrigins = await dataNavOrigins.json();
            setNavOrigins(navOrigins);
        };
        fetchData();
    }, [hosting]);
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/origines" element={<Origins navParks={navParks} ori={navOrigins} att={navAttractions} cha={navCaracters} />} />
                <Route path="*" element={<NotFound navParks={navParks} />} />
            </Routes>
        </BrowserRouter>
    )
}