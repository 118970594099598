import { useParams } from 'react-router-dom';
import { HostBackend } from '../../../Routes/HostBackend.js';
import { Fields } from '../Update/Fields.js';

export const FormAdminUpdate = (props) => {
    const hosting = HostBackend();
    const { entity } = useParams();
    const datas = props.datas;
    return (
        <form className="form" action={`${hosting}/admin/update/${entity}`} method="POST">
            <fieldset>
                <legend>{`UPDATE: ${entity}`}</legend>
                {datas.map((item, i) => {
                    return (
                        <Fields key={`i-${i}`} entity={entity} item={item} />
                    )
                })}
                <input className="button" type="submit" defaultValue="Update" />
            </fieldset>
        </form>
    )
}