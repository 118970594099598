import { CreatedAt } from '../Document/CreatedAt.js';
import { BlockLink } from './BlockLink.js';
import { BlockNameTotalAuthor } from './BlockNameTotalAuthor.js';

export const BlockTitle = (props) => {
    const item = props.item;
    const totalAndLabel = props.total ? props.total : '';
    switch (props.label) {
        case 'park':
        case 'origins':
        case 'categories':
        case 'characters':
        case 'outlinks':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor name={item.name} total={totalAndLabel} author={item.author} />
                    </div>
                </div>
            )
        case 'univers':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor name={item.uname} total={totalAndLabel} author={item.author} />
                        <BlockLink label="link-true" href={item.proute} name={item.pname} />
                    </div>
                </div>
            )
        case 'category':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor name={item.cname} total={totalAndLabel} author={item.author} />
                        <BlockLink label="link-true" href={'/categories'} name={'Catégories'} />
                    </div>
                </div>
            )
        case 'origin':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor name={item.name} total={totalAndLabel} author={item.author} />
                        <BlockLink label="link-true" href={'/origines'} name={'Origines'} />
                    </div>
                </div>
            )
        case 'character':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor name={item.name} total={totalAndLabel} author={item.author} />
                        <BlockLink label="link-true" href={'/personnages'} name={'personnages'} />
                        <BlockLink label="link-true" href={'/origine/walt-disney-imagineering'} name={'Walt Disney Imagineering'} />
                        <BlockLink label={`link-${item.slug}`} href={'/experiences/stitch'} name={'Expérences Stitch'} />
                    </div>
                </div>
            )
        case 'attraction':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor name={item.aname} total={totalAndLabel} author={item.author} />
                        <BlockLink label="link-true" href={`/categorie/${item.cslug}`} name={item.cname} />
                        <BlockLink label="link-true" href={`/origine/${item.oslug}`} name={item.oname} />
                    </div>
                </div>
            )
        case 'experiment':
            return (
                <div className="block-title">
                    <CreatedAt date={item.created} />
                    <div className="title">
                        <BlockNameTotalAuthor name={item.name} total={totalAndLabel} author={item.authors.join(', ')} />
                        {item.authors.map((author, i) => {
                            return (
                                <BlockLink label="link-true" href={`${item.deviantart}${author}`} name={author} key={`i-${i}`} />
                            )
                        })}
                    </div>
                </div>
            )
        default:
            return (
                <></>
            )
    }
}