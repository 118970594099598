export const GetEntitySelector = (entity) => {
    let selector;
    if (entity === 'attraction') 
        selector = 'category';
    else if (entity === 'characters')
        selector = 'origin';
    else if (entity === 'description')
        selector = 'sort';
    else if (entity === 'origin')
        selector = 'origintype';
    else 
        selector = 'sort';
    return selector;
}