import { Navigation } from '../../Components/Admin/Document/Navigation.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { FormAdminInsert } from '../../Components/Admin/Form/FormAdminInsert.js';
import { AutoComplete } from '../../Components/Admin/Functions/AutoComplete.js';

export const Insert = (props) => {
    const entity = props.entity;
    window.addEventListener('load', (ev) => {
        AutoComplete();
    });
    return (
        <div className="insert">
            <Navigation entity={entity} chooser={[]} />
            <div className="admin-form">
                <FormAdminInsert entity={entity} />
                <a className="back" href={`/admin/entity/collection/${entity}`} alt="back" title="back">
                    <i className="fa fa-reply"></i>
                </a>
            </div>
            <Footer />
        </div>
    )
}