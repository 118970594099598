import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* admin routes */
import PrivateRoute from '../../Components/Admin/Functions/PrivateRoute.js';
import { Insert } from '../../Pages/Admin/Insert.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteInsert = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PrivateRoute />}>
                    <Route path={`/admin/entity/insert/:entity`} element={<Insert />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}