import { GetBkgHeader } from "../Functions/GetBkgHeader";

export const Header = () => {
    const uri = window.location.href;
    const bkgHeader = GetBkgHeader(uri);
    return (
        <header id="header" className={`header img-${bkgHeader}`}>
            <a className="logo-title" href="/" alt="Home">
                <div className="item1">Rencontre<div className="mickey"></div></div>
                <div className="item2">ta Souris</div>
                <div className="item3">à Disneyland Paris</div>
            </a>
            <div className="logo-text">
                Liste des attractions et personnages de Disneyland Paris
                classées par parcs, univers, catégories et origines.
            </div>
        </header>
    )
}
