export const SortASC = (data) => {
    data.sort((a, b) => {
        /* attractions */
        if (a.aslug) {
            if (a.aslug < b.aslug) return -1;
            if (a.aslug > b.aslug) return 1;
        }
        /* univers */
        if (a.uslug) {
            if (a.uslug < b.uslug) return -1;
            if (a.uslug > b.uslug) return 1;
        }
        /* categories */
        if (a.cslug) {
            if (a.cslug < b.cslug) return -1;
            if (a.cslug > b.cslug) return 1;
        }
        /* origins */
        if (a.oslug) {
            if (a.oslug < b.oslug) return -1;
            if (a.oslug > b.oslug) return 1;
        }
        return 0;
    });
    return data;
}