export const HostWebsocket = () => {
    let hostWebsocket, protocol, host;
    const hostname = window.location.hostname;
    if (hostname === 'rencontretasouris.com') {
        protocol = 'wss://';
        host = hostname;
    } else {
        protocol = 'ws://';
        host = 'localhost';
    }
    hostWebsocket = `${protocol}${host}/ws`;
    return hostWebsocket;
}