export const GetIconCode = (code) => {
    let classname = '';
        /* categorie */
        if (code === 'CT') classname = 'fa fa-cogs';
        /* origine */
        if (code === 'OR') classname = 'fa fa-share-alt';
        /* liens */
        if (code === 'LK') classname = 'fa fa-link';
        /* aire de jeu */
        if (code === 'AJ') classname = 'fa fa-paw';
        /* decouverte */
        if (code === 'DV') classname = 'fa fa-eye';
        /* jeu arcade */
        if (code === 'JA') classname = 'fa fa-gamepad';
        /* manege theme */
        if (code === 'MT') classname = 'fa fa-puzzle-piece';
        /* manege fixe */
        if (code ==='MF') classname = 'fa fa-university';
        /* montagnes russes */
        if (code === 'MR') classname = 'fa fa-rocket';
        /* parcours */
        if (code === 'PA') classname = 'fa fa-map';
        /* personnage */
        if (code === 'PG') classname = 'fa fa-street-view';
        /* point d'intéret */
        if (code === 'PI') classname = 'fa fa-map-marker';
        /* spectacle */
        if (code === 'SP') classname = 'fa fa-star';
        /* vegetal */
        if (code === 'VG') classname = 'fa fa-leaf';
    return classname;
}