import { useState } from 'react';
import { FilterBkgColor } from '../Filters/FilterBkgColor';
import { Finder } from '../Document/Finder.js';

export const Navigation = (props) => {
    const navParks = props.navParks ? props.navParks : [];
    const bkgNav = FilterBkgColor();
    const [show0, setShow0] = useState('hide');
    const [show1, setShow1] = useState('hide');
    const [show2, setShow2] = useState('hide');
    return (
        <div className={`navigation ${bkgNav}`}>
            <div className="buttons">
                <div className="slider" onClick={() => { show0 === 'show' ? setShow0('hide') : setShow0('show'); }}>
                    <i className="fa fa-bars"></i>
                </div>
                <div className={`nav ${show0}`}>
                    {navParks.map((p, i) => {
                        let show = (p.pid === 1) ? show1 : show2;
                        return (
                            <div className="nav-parent" key={`i-${i}`}>
                                <div id={p.pid} className={`link show ${bkgNav}`} onClick={(e) => {
                                    if (e.target.id === '1') { setShow1('show'); setShow2('hide') }
                                    if (e.target.id === '2') { setShow2('show'); setShow1('hide') }
                                }}>{p.pname}
                                </div>
                                <div className={`nav-childs ${show}`}>
                                    {p.univers.map((u, j) => {
                                        return (<a className={`link link-${p.pid}`} href={u.uroute} key={`j-${j}`}>{u.uname}</a>)
                                    })}
                                </div>
                            </div>
                        )
                    })}
                    <a className="link link-3" href="/categories" alt="categories">Catégories</a>
                    <a className="link link-3" href="/origines" alt="origines">Origines</a>
                    <a className="link link-3" href="/personnages" alt="personnages">Personnages</a>
                    <a className="link link-3" href="/experiences/stitch" alt="experiences">Expériences</a>
                    <a className="link link-3" href="/liens" alt="liens">Liens externes</a>
                </div>
                <a className="logo-nav" href="/" alt="Home">
                    <i className="fa fa-home"></i>
                </a>
            </div>
            <div className="buttons">
                <Finder />
            </div>
        </div>
    )
}