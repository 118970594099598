import { useState, useEffect } from 'react';
/* datas */
import { HostBackend } from '../../Routes/HostBackend.js';
import { LogoImgDefault } from '../../Files/Logos/LogoImgDefault.js';
/* document */
import { Navigation } from '../../Components/Public/Document/Navigation.js';
import { Header } from '../../Components/Public/Document/Header.js';
import { Footer } from '../../Components/Public/Document/Footer.js';
import { BreadCrumb } from '../../Components/Public/Document/Breadcrumb.js';
import { Carrousel } from '../../Components/Public/Carrousel/Carrousel.js';
import { BlockLogo } from '../../Components/Public/Blocks/BlockLogo.js';
import { InfosCharacter } from '../../Components/Public/Blocks/InfosCharacter.js';
import { BlockTitle } from '../../Components/Public/Blocks/BlockTitle.js';
import { BlockOutlink } from '../../Components/Public/Blocks/BlockOutlink.js';
import { BackTop} from '../../Components/Public/Document/BackTop.js';
import { ScrollTop } from '../../Components/Public/Functions/ScrollTop.js';

export const Character = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const id = parseInt(props.id);
    const [character, setCharacter] = useState([]);
    useEffect(() => {
        const fetchtheData = async () => {
            const dataCharacter = await fetch(`${hosting}/character?id=${id}`);
            const character = await dataCharacter.json();
            setCharacter(character);
        };
        fetchtheData();
        ScrollTop();
    }, [hosting, id]);
    let items = {};
    const collection = [];
    const logoImgDefault = LogoImgDefault('img');
    for (let i = 0; i < character.length; i++) {
        collection.push({
            name: character[i].aname,
            href: character[i].aroute,
            images: logoImgDefault,
        });
    }
    return (
        <div className="character">
            <Navigation navParks={navParks}/>
            <Header />
            <Carrousel label="character" datas={collection} total={logoImgDefault.length} />
            <BreadCrumb />
            <section className="infos">
                {character.map((item, i) => {
                    items = item;
                    return (
                        <div className="bloc-infos" key={`i-${i}`}>
                            <BlockLogo label="character" item={item} />
                            <InfosCharacter datas={character} />
                        </div>
                    )
                })}
                <div className="description">
                    <BlockTitle label="character" item={items} />
                    <p>{items.description}</p>
                    <BlockOutlink item={items} />
                </div>
            </section>
            <BackTop />
            <Footer />
        </div>
    )
}