import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HostBackend } from '../HostBackend.js';
import { Character } from '../../Pages/Public/Character.js';
import { NotFound } from '../../Pages/Public/NotFound.js';

export const RouteCharacter = (props) => {
    const navParks = props.navParks;
    const hosting = HostBackend();
    const [navCharacters, setNavCharacters] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const dataNavCharacters = await fetch(`${hosting}/nav_characters`);
            const navCharacters = await dataNavCharacters.json();
            setNavCharacters(navCharacters);
        };
        fetchData();
    }, [hosting]);
    return (
        <BrowserRouter>
            <Routes>
                {navCharacters.map((el, i) => {
                    return (<Route path={el.route} element={<Character navParks={navParks} id={el.id} slug={el.slug} />} key={`c-${i}`} />)
                })}
                <Route path="*" element={<NotFound navParks={navParks} />} />
            </Routes>
        </BrowserRouter>
    )
}